import React, { useEffect } from "react";

/**
 * Simple hook to register a handler that should be called
 * whenever the user clicks "outside" of given ref element.
 */
export function useClickAway(
  ref: React.RefObject<HTMLElement>,
  handler: (event: MouseEvent) => void
): void {
  useEffect(() => {
    function handleClickAway(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler(event);
      }
    }

    document.addEventListener("mousedown", handleClickAway);
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [handler, ref]);
}
