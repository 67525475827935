import React from "react";
import { MapKind } from "components";

export interface BadgeProps {
  size?: "regular" | "large" | "big";
  type: MapKind;
  level?: number;
  minLevel?: number;
}

export function Badge({
  size = "regular",
  type,
  level,
  minLevel,
}: BadgeProps): JSX.Element {
  let label = "";
  if (type === "warnMap" && level !== undefined) {
    label =
      minLevel !== undefined && minLevel !== level
        ? `${minLevel}-${level}`
        : `${level}`;
  }

  return (
    <div className={`badge-component ${size} ${type}-${level}`}>{label}</div>
  );
}
