import React, { createContext, useContext } from "react";

export const DEFAULT_LOCALE = "de";
export interface Locales {
  t: (key: string, params?: Record<string, string>) => string;
  localizedAttr: (key: string, source: Record<string, unknown>) => string;
  locale: string;
}

type Dict = {
  [x: string]: unknown;
};

const context = createContext<Locales>({
  t() {
    console.warn("t called before provided by LocaleContext");
    return "";
  },
  localizedAttr() {
    console.warn("localizedAttr called before provided by LocaleContext");
    return "";
  },
  locale: DEFAULT_LOCALE,
});

export function useLocales(): Locales {
  return useContext(context);
}

function getTranslation(locales: Dict, key: string): unknown {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return key.split(".").reduce((o, i) => (o as any)[i], locales);
  } catch (e) {}
}

export function LocaleProvider({
  children,
  locales,
  fallbackLocales,
  currentLocale,
}: {
  children: React.ReactNode;
  locales: Dict;
  fallbackLocales: Dict;
  currentLocale: string; // locales key -> (de, it, fr)
}): JSX.Element {
  const t = (key: string, params?: Record<string, string>) => {
    let res =
      getTranslation(locales, key) || getTranslation(fallbackLocales, key);

    if (!res) {
      console.warn(`could not find translation for ${key}`, locales);
      res = `translation missing: ${key}`;
    }

    let value = String(res);

    // Replace placeholders in value
    if (params) {
      Object.keys(params).forEach(
        (key) => (value = value.replaceAll(`%{${key}}`, params[key]))
      );
    }

    return value;
  };

  /**
   * Helper function to get a localized version of a record attribute.
   * Current locale gets suffixed to the "key" with a leading underscore.
   * Tries to provide a fallback with DEFAULT_LOCALE if localized attr
   * could not be found.
   */
  const localizedAttr = (
    key: string,
    source: Record<string, unknown>
  ): string => {
    const prettyPrint = (src: Record<string, unknown>) =>
      JSON.stringify(src, null, 4);

    const fallbackKey = `${key}_${DEFAULT_LOCALE}`;
    const localizedKey = `${key}_${currentLocale}`;

    if (!source.hasOwnProperty(localizedKey)) {
      console.warn(
        `could not find key "${localizedKey}" in ${prettyPrint(source)}`
      );
      if (!source.hasOwnProperty(fallbackKey)) {
        console.warn(
          `could not find fallback key "${fallbackKey}" in ${prettyPrint(
            source
          )}`
        );
        return "";
      }
      return String(source[fallbackKey]);
    }

    return String(source[localizedKey]);
  };

  return (
    <context.Provider value={{ t, localizedAttr, locale: currentLocale }}>
      {children}
    </context.Provider>
  );
}
