import { useCallback, useEffect, useReducer, useRef } from "react";

type Method = "GET" | "POST";
export interface UseFetchProps {
  query?: Query;
  url: string;
  options?: {
    throwOnError?: boolean;
    enabled?: boolean;
    method?: Method;
  };
}

type Action<T> = {
  type: "LOADING" | "SUCCESS" | "ERROR";
  payload?: T;
};

interface State<T> {
  status: UseFetchStatus;
  error: unknown;
  data: T;
}

export type UseFetchStatus = "idle" | "loading" | "success" | "error";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: State<any> = {
  status: "idle",
  error: null,
  data: null,
};

const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case "LOADING":
      return { ...initialState, status: "loading" };
    case "SUCCESS":
      return {
        ...initialState,
        status: "success",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: action.payload as any,
      };
    case "ERROR":
      return { ...initialState, status: "error", error: action.payload };
    default:
      return state;
  }
};

export type Query = Record<string, string | number | Array<number | string>>;

function buildQueryParams(obj: Query): string {
  const res: Array<string> = [];
  Object.entries(obj)
    .filter(([, value]) => Boolean(value))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => res.push(`${key}[]=${String(val)}`));
      } else {
        res.push(`${key}=${String(value)}`);
      }
    });
  return res.join("&");
}

export function buildFetchUrl(url: string, query?: Query): string {
  const queryString = buildQueryParams(query || {});
  return queryString.length ? `${url}?${queryString}` : url;
}
const defaultOptions = { throwOnError: false, enabled: true, method: "GET" };
export function useFetch<T>({
  url,
  query,
  options: optionsParam = {},
}: UseFetchProps): State<T> & {
  refetch: (
    url: UseFetchProps["url"],
    query: UseFetchProps["query"]
  ) => Promise<T>;
} {
  const mountedRef = useRef(false);
  const queryRef = useRef("");
  const queryInitiallyLoaded = useRef(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const options = { ...defaultOptions, ...optionsParam };
  const makeRequest = useCallback(
    async (url: string, query?: Query) => {
      const safeDispatch = (action: Action<T>) => {
        if (mountedRef.current) {
          dispatch(action);
        }
      };

      safeDispatch({ type: "LOADING" });
      try {
        let res: Response;
        if (options.method === "POST") {
          res = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(query),
          });
        } else {
          const queryString = buildQueryParams(query || {});
          res = await fetch(queryString.length ? `${url}?${queryString}` : url);
        }

        const payload = await res.json();
        if (!res.ok) {
          safeDispatch({ type: "ERROR", payload });
          if (options.throwOnError) {
            throw payload;
          }
          return;
        }
        safeDispatch({ type: "SUCCESS", payload });
        return payload;
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        safeDispatch({ type: "ERROR", payload: e as any });
        if (options.throwOnError) {
          throw e;
        }
      }
    },
    [options]
  );

  useEffect(() => {
    mountedRef.current = true;
    const fetchUrl = buildFetchUrl(url, query);
    if (queryRef.current === fetchUrl && queryInitiallyLoaded.current) {
      return;
    }

    if (options.enabled) {
      queryRef.current = fetchUrl;
      queryInitiallyLoaded.current = true;
      makeRequest(url, query);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [options, url, makeRequest, query]);

  const { data, ...rest } = state;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { ...rest, data: data as any, refetch: makeRequest };
}
