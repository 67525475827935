import React from "react";
import { createRoot } from "react-dom/client";

import { LocaleProvider, DEFAULT_LOCALE } from "hooks";

/**
 * Helper function to mount a react app to an html node. The Rails helper
 * (react_component) expects locales file under `config/locales/web` with
 * the name `mountNodeId` in all accessible languages.
 */
export function mount<T>(Component: React.FC<T>, mountNodeId: string): void {
  document.addEventListener("DOMContentLoaded", () => {
    const mountNode = document.getElementById(mountNodeId);
    if (mountNode) {
      const propsJSON = mountNode.getAttribute("data-react-props");
      const props = JSON.parse(propsJSON || "{}");
      const { locales, fallbackLocales, currentLocale, ...rest } = props;

      const root = createRoot(mountNode);
      root.render(
        <LocaleProvider
          locales={JSON.parse(locales || "{}")}
          fallbackLocales={JSON.parse(fallbackLocales || "{}")}
          currentLocale={currentLocale || DEFAULT_LOCALE}
        >
          <Component {...rest} />
        </LocaleProvider>
      );
    } else {
      console.warn("Unable create React root, node not available");
    }
  });
}

export function displayDate(input: Date | string): string {
  const date = typeof input === "string" ? new Date(input) : input;
  return `${date.toLocaleDateString("de-DE", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  })}`;
}

export function displayDateTime(input: Date | string): string {
  const date = typeof input === "string" ? new Date(input) : input;
  if (!isValidDate(date)) return "";

  return `${date.toLocaleDateString("de-DE", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  })}`;
}

const isValidDate = (date: unknown) =>
  date instanceof Date && !isNaN(date.valueOf());
