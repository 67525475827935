import React from "react";
import { useLocales } from "hooks";

export const SwissMapTouchDragOverlay = React.forwardRef<HTMLDivElement>(
  (_, ref) => {
    const { t } = useLocales();
    return (
      <div
        ref={ref}
        className="map-touch-drag-overlay"
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          color: "#ffffff",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "none",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "120%",
        }}
      >
        {t("touchDragHint")}
      </div>
    );
  }
);

SwissMapTouchDragOverlay.displayName = "SwissMapTouchDragOverlay";
