import { useState } from "react";
import { useClickAway } from "hooks";

export interface UseMapLegendProps {
  triggerRef: React.RefObject<HTMLElement>;
  legendRef: React.RefObject<HTMLElement>;
}

export type UseMapLegendResult = [boolean, () => void];

export function useMapLegend({
  triggerRef,
  legendRef,
}: UseMapLegendProps): UseMapLegendResult {
  const [shown, setShown] = useState(false);
  const handleClickAway = (e: MouseEvent) => {
    if (triggerRef.current?.contains(e.target as Node)) {
      // ignore, button handler will handle the event.
      return;
    }
    setShown(false);
  };

  const toggleShown = () => setShown(!shown);

  useClickAway(legendRef, handleClickAway);
  return [shown, toggleShown];
}
