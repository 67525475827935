import { useEffect, useMemo, useState } from "react";
import { FeatureLike } from "ol/Feature";
import { maxBy, minBy } from "lodash-es";

import { Canton, MapKind } from "components";
import { useFetch, useLocales } from "hooks";

interface WarnMapJsonEntry {
  [x: string]: unknown;
  region_id: number;
  region_name_de: number;
  region_name_fr: number;
  region_name_it: number;
  region_name_en: number;
  canton_id: number;
  level: number;
  valid_from: string;
}

interface MeasureRegionJsonEntry {
  [x: string]: unknown;
  region_id: number;
  region_name_de: number;
  region_name_fr: number;
  region_name_it: number;
  region_name_en: number;
  canton_id: number;
  measure_id: number;
  category: number;
  valid_from: string;
}

interface MeasureJsonEntry {
  [x: string]: unknown;
  id: number;
  category: number;
  title_de: string;
  title_fr: string;
  title_it: string;
  title_en: string;
  short_title_de: string;
  short_title_fr: string;
  short_title_it: string;
  short_title_en: string;
  description_de: string;
  description_fr: string;
  description_it: string;
  description_en: string;
}

interface WarnMapFeatureProps {
  [x: string]: unknown;
  fire_region_id: number;
  canton_id: number;
  name_de: string;
  name_it: string;
  name_fr: string;
  name_en: string;
  level: number;
  valid_from: string;
  published_at: string;
}

interface MeasureRegionFeatureProps {
  [x: string]: unknown;
  id: number;
  canton_id: number;
  name_de: string;
  name_it: string;
  name_fr: string;
  name_en: string;
  fire_measure_id: number;
  category: number;
  title_de: string;
  title_it: string;
  title_fr: string;
  title_en: string;
  valid_from: string;
  published_at: string;
}

export interface Measure {
  id: number;
  category: number;
  title: string;
  shortTitle: string;
  description: string;
}

export interface MapNavigatorItem {
  id: number;
  parentId?: number;
  name: string;
  minLevel?: number;
  level?: number;
  levelName?: string;
  validFrom?: string;
  measure?: Measure;
}

export interface UseMapNavigatorItemsProps {
  type: MapKind;
  cantons: ReadonlyArray<Canton>;
  regionsJsonPath?: string;
  measuresJsonPath?: string;
  features?: ReadonlyArray<FeatureLike>;
}

export interface UseMapNavigatorItemsResult {
  cantonItems: ReadonlyArray<MapNavigatorItem>;
  regionItems: ReadonlyArray<MapNavigatorItem>;
}

export enum MapNavigatorSorting {
  Level = "level",
  NameAsc = "nameAsc",
  NameDesc = "nameDesc",
}
export const MAP_NAVIGATOR_SORTING = Object.values(MapNavigatorSorting);

export type UseMapNavigatorSortedItemsResult = UseMapNavigatorItemsResult & {
  sorting: MapNavigatorSorting;
  setSorting: (sorting: MapNavigatorSorting) => void;
};

/**
 * Returns sorted items from the `useMapNavigatorItems` hook.
 */
export function useMapNavigatorSortedItems(
  props: UseMapNavigatorItemsProps
): UseMapNavigatorSortedItemsResult {
  const { cantonItems, regionItems } = useMapNavigatorItems(props);
  const [sorting, setSorting] = useState<MapNavigatorSorting>(
    MapNavigatorSorting.Level
  );

  const sortedCantonItems = useMemo(() => {
    switch (sorting) {
      case MapNavigatorSorting.NameAsc:
        return [...cantonItems].sort(byNameComparator);
      case MapNavigatorSorting.NameDesc:
        return [...cantonItems].sort(byNameComparator).reverse();
      default:
        return [...cantonItems].sort(byLevelComparator);
    }
  }, [sorting, cantonItems]);

  const sortedRegionItems = useMemo(() => {
    switch (sorting) {
      case MapNavigatorSorting.NameAsc:
        return [...regionItems].sort(byNameComparator);
      case MapNavigatorSorting.NameDesc:
        return [...regionItems].sort(byNameComparator).reverse();
      default:
        return [...regionItems].sort(byLevelComparator);
    }
  }, [sorting, regionItems]);

  return {
    cantonItems: sortedCantonItems,
    regionItems: sortedRegionItems,
    sorting,
    setSorting,
  };
}

/**
 * This hook prepares the data required by the `MapNavigator`
 * component. There are two scenarios that represent two
 * different data sources:
 *
 * - Static map image (as used in `FireMapTabs`):
 *   Here we have no data about the regions available, therefore
 *   it is fetched from `regionsJsonPath`.
 *
 * - Interactive map (as used in `FireMapDetail`):
 *   Here we can (re-)use the available data from the GeoJson features.
 */
export function useMapNavigatorItems({
  type,
  cantons,
  regionsJsonPath,
  measuresJsonPath,
  features,
}: UseMapNavigatorItemsProps): UseMapNavigatorItemsResult {
  const regionItems = useMapNavigatorRegions({
    type,
    regionsJsonPath,
    measuresJsonPath,
    features,
  });
  const cantonItems = useMapNavigatorCantons({
    type,
    cantons,
    regionItems,
  });

  return { cantonItems, regionItems };
}

function useMapNavigatorCantons({
  type,
  cantons,
  regionItems,
}: Pick<UseMapNavigatorItemsProps, "type" | "cantons"> & {
  regionItems: ReadonlyArray<MapNavigatorItem>;
}) {
  const { t } = useLocales();
  const [navigatorItems, setCantonItems] =
    useState<ReadonlyArray<MapNavigatorItem>>(cantons);

  // Update cantonItems with min/max levels when regionItems are available
  useEffect(() => {
    setCantonItems(
      cantons.map((canton) => {
        const regions = regionItems.filter((r) => r.parentId === canton.id);
        const maxRegion = maxBy(regions, (r) => r.level);
        const minRegion =
          type === "warnMap" ? minBy(regions, (r) => r.level) : undefined;
        const prefix =
          canton.abbr === "FL" ? "" : `${t("navigator.canton_prefix")} `;
        return {
          ...canton,
          name: `${prefix}${canton.name}`,
          minLevel: minRegion?.level,
          level: maxRegion?.level,
          levelName: maxRegion?.levelName,
        };
      })
    );
  }, [t, type, cantons, regionItems]);

  return navigatorItems;
}

function useMapNavigatorRegions({
  type,
  regionsJsonPath,
  measuresJsonPath,
  features,
}: Omit<UseMapNavigatorItemsProps, "cantons">) {
  const { localizedAttr } = useLocales();
  const [regionItems, setRegionItems] = useState<
    ReadonlyArray<MapNavigatorItem>
  >([]);

  const { data: measuresData } =
    useFetch<ReadonlyArray<MeasureJsonEntry> | null>({
      url: measuresJsonPath ?? "",
      options: { enabled: Boolean(measuresJsonPath) },
    });
  const measures: Record<number, Measure> = useMemo(
    () =>
      measuresData
        ? measuresData.reduce(
            (acc, measure) => ({
              ...acc,
              [measure.id]: {
                id: measure.id,
                category: measure.category,
                title: localizedAttr("title", measure),
                shortTitle: localizedAttr("short_title", measure),
                description: localizedAttr("description", measure),
              },
            }),
            {}
          )
        : {},
    [measuresData, localizedAttr]
  );

  // Use regions data from GeoJSON features
  useEffect(() => {
    function warnMapFeatureToItem(feature: FeatureLike): MapNavigatorItem {
      const props = feature.getProperties() as WarnMapFeatureProps;
      return {
        id: props.fire_region_id,
        name: localizedAttr("name", props),
        parentId: props.canton_id,
        level: props.level,
        validFrom: props.valid_from,
      };
    }

    function measureFeatureToItem(feature: FeatureLike): MapNavigatorItem {
      const props = feature.getProperties() as MeasureRegionFeatureProps;
      const measure = measures[props.fire_measure_id];
      return {
        id: props.id,
        name: localizedAttr("name", props),
        parentId: props.canton_id,
        level: props.category,
        levelName: measure?.shortTitle,
        validFrom: props.valid_from,
        measure,
      };
    }

    if (features !== undefined) {
      setRegionItems(
        type === "measures"
          ? features.map(measureFeatureToItem)
          : features.map(warnMapFeatureToItem)
      );
    }
  }, [type, features, measures, localizedAttr, setRegionItems]);

  // Fetch regions data asynchronously
  const { data: regionsData } = useFetch<ReadonlyArray<
    WarnMapJsonEntry | MeasureRegionJsonEntry
  > | null>({
    url: regionsJsonPath ?? "",
    options: { enabled: features === undefined && Boolean(regionsJsonPath) },
  });

  // Update regionItems after regions data has been fetched
  useEffect(() => {
    function warnMapJsonEntryToItem(entry: WarnMapJsonEntry): MapNavigatorItem {
      return {
        id: entry.region_id,
        name: localizedAttr("region_name", entry),
        parentId: entry.canton_id,
        level: entry.level,
        validFrom: entry.valid_from,
      };
    }

    function measureJsonEntryToItem(
      entry: MeasureRegionJsonEntry
    ): MapNavigatorItem {
      const measure = measures[entry.measure_id];
      return {
        id: entry.region_id,
        name: localizedAttr("region_name", entry),
        parentId: entry.canton_id,
        level: entry.category,
        levelName: measure?.shortTitle,
        validFrom: entry.valid_from,
        measure,
      };
    }

    if (regionsData) {
      setRegionItems(
        type === "measures"
          ? (regionsData as ReadonlyArray<MeasureRegionJsonEntry>).map(
              measureJsonEntryToItem
            )
          : (regionsData as ReadonlyArray<WarnMapJsonEntry>).map(
              warnMapJsonEntryToItem
            )
      );
    }
  }, [type, regionsData, measures, localizedAttr]);

  return regionItems;
}

function byLevelComparator(a: MapNavigatorItem, b: MapNavigatorItem): number {
  const levelA = a.level ?? 0;
  const levelB = b.level ?? 0;
  return levelA === levelB ? byNameComparator(a, b) : levelB - levelA;
}

function byNameComparator(a: MapNavigatorItem, b: MapNavigatorItem): number {
  return a.name.localeCompare(b.name);
}
