import React, { useRef } from "react";

import { useLocales, useMapLegend } from "hooks";
import { MapKind, MapLegend } from "components";

export interface SwissMapControlsProps {
  onZoomOut: () => void;
  onZoomIn: () => void;
  type: MapKind;
  legendLink?: string;
}

export const SwissMapControls = ({
  onZoomOut,
  onZoomIn,
  type,
  legendLink,
}: SwissMapControlsProps): JSX.Element => {
  const { t } = useLocales();
  const legendRef = useRef(null);
  const triggerRef = useRef(null);
  const [shown, toggleShown] = useMapLegend({ triggerRef, legendRef });

  return (
    <React.Fragment>
      <div className="map-controls">
        {legendLink && (
          <button
            ref={triggerRef}
            type="button"
            className="btn btn-default map-control"
            style={{ marginBottom: "12px" }}
            onClick={toggleShown}
            aria-label={t("info")}
            value="info"
          >
            <span className={`icon icon--${shown ? "close" : "list"}`} />
          </button>
        )}

        <button
          onClick={onZoomIn}
          type="button"
          className="btn btn-default map-control zoom-control-in icon-like"
          aria-label={t("zoomIn")}
        >
          <span>+</span>
        </button>

        <button
          type="button"
          onClick={onZoomOut}
          className="btn btn-default map-control zoom-control-out icon-like"
          aria-label={t("zoomOut")}
        >
          <span>-</span>
        </button>
      </div>

      {legendLink && (
        <MapLegend
          type={type}
          shown={shown}
          ref={legendRef}
          link={legendLink}
        />
      )}
    </React.Fragment>
  );
};
