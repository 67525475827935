import React from "react";
import { Badge, MapKind } from "components";
import { useLocales } from "hooks";

export interface MapLegendProps {
  type: MapKind;
  shown: boolean;
  link: string;
}

export const MapLegend = React.forwardRef<HTMLDivElement, MapLegendProps>(
  // eslint-disable-next-line react/prop-types
  ({ shown, type, link }: MapLegendProps, ref) => {
    const { t } = useLocales();
    const levelOrCategory =
      type === "warnMap" ? [1, 2, 3, 4, 5] : [0, 1, 2, 3, 4];

    return (
      <React.Fragment>
        {shown && (
          <div ref={ref} className={`map-legend map-legend-${type}`}>
            <h2 className="map-legend-title">{t(`legend.titles.${type}`)}</h2>
            <div>
              {levelOrCategory.map((level) => (
                <div className="map-legend-row" key={level}>
                  <Badge level={level} type={type} />
                  <div>
                    <a href={link}>{t(`legend.${type}.${level}`)}</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
);

MapLegend.displayName = "MapLegend";
