import React from "react";
import { Map as OlMap, MapBrowserEvent } from "ol";
import { Image as ImageLayer, Layer } from "ol/layer";
import OlFeature from "ol/Feature";
import View from "ol/View";
import OlVectorLayer from "ol/layer/Vector";
import Kinetic from "ol/Kinetic";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { get as projGet } from "ol/proj";
import { defaults as defaultInteractions, DragPan } from "ol/interaction";
import Static from "ol/source/ImageStatic";
import Geometry from "ol/geom/Geometry";
import format from "date-fns/format";
import VectorSource from "ol/source/Vector";
import { ScaleLine } from "ol/control";

import { SwissMapControls } from "./SwissMapControls";
import { MapKind } from "components";
import { SwissMapTouchDragOverlay } from "./SwissMapTouchDragOverlay";
import { Pixel } from "ol/pixel";

export enum SwissMapInteractions {
  ClickSelection = 0,
}

export enum SwissMapLayers {
  Background = 0,
  Text = 1,
  Vector = 2,
}

type Feature = OlFeature<Geometry>;
type VectorLayer = OlVectorLayer<VectorSource<Geometry>>;

export interface SwissMapProps {
  vectorLayer: VectorLayer;
  textLayer?: VectorLayer;
  background: string;
  type: MapKind;
  legendLink?: string;
  producedAt?: Date;
  onClick?: (features: Array<Feature>) => void;
  onHover?: (features: Array<Feature>) => void;
  onInitialized?: (map: OlMap) => void;
  zoomMin?: number;
  zoomMax?: number;
  onZoomLevelChange?: () => void;
  clickFilter?: (feature: Feature, layer: Layer) => boolean;
  enableMouseWheelZoom?: boolean;
}

// Map extent (limits) for Switzerland, see https://epsg.io/2056
const MAP_EXTENT = [2470287, 1058737, 2852879, 1310264];
export const MAP_CENTER = [2660013.54, 1185171.98];

const ZOOM_MIN_DEFAULT = 0;
const ZOOM_MAX_DEFAULT = 1;
const ZOOM_STEP = 1;

export class SwissMap extends React.Component<SwissMapProps> {
  private mapRef = React.createRef<HTMLDivElement>();
  private touchDragOverlayRef = React.createRef<HTMLDivElement>();
  private map: OlMap;

  constructor(props: SwissMapProps) {
    super(props);
    const map = this.initMap();
    this.map = map;
  }

  componentDidMount(): void {
    // react guarantees that refs are set before componentDidMout, so its
    // safe to silence the ts-linter here.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const mapEl = this.mapRef.current!;
    this.map.setTarget(mapEl);
    this.registerEventListeners();
    this.setZoomLimits();

    if (this.props.onInitialized) {
      this.props.onInitialized(this.map);
    }
  }

  private initMap(): OlMap {
    proj4.defs(
      "EPSG:2056",
      "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs"
    );

    register(proj4);
    projGet("EPSG:2056")?.setExtent(MAP_EXTENT);

    const mouseOrTwoFingerDragPan = new DragPan({
      kinetic: new Kinetic(-0.005, 0.05, 100),
      condition: function () {
        // Only pan the map on touch devices if dragged with two fingers
        const isTouch = "ontouchstart" in window;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return !isTouch || (this as any).getPointerCount() === 2;
      },
    });
    const interactions = defaultInteractions({
      dragPan: false, // See custom DragPan action below
      pinchRotate: false,
      mouseWheelZoom: Boolean(this.props.enableMouseWheelZoom),
    }).extend([mouseOrTwoFingerDragPan]);

    const scaleControl = new ScaleLine({
      units: "metric",
    });

    // create olMap instance
    const map = new OlMap({
      layers: [],
      interactions,
      controls: [scaleControl],
      view: new View({
        projection: "EPSG:2056",
        center: MAP_CENTER,
        extent: MAP_EXTENT,
        showFullExtent: true,
        zoom: 0,
      }),
      overlays: [],
    });

    // this needs to match with the extents defined in core/config/settings.yml
    const imageExtent = [
      2486287.8472228525, 1060737.6268667735, 2832979.9027771475,
      1310464.2481332265,
    ];

    const backgroundLayer = new ImageLayer({
      source: new Static({
        url: this.props.background,
        imageExtent,
      }),
    });

    map.getLayers().insertAt(SwissMapLayers.Background, backgroundLayer);
    map.getLayers().insertAt(SwissMapLayers.Vector, this.props.vectorLayer);
    if (this.props.textLayer) {
      map.getLayers().insertAt(SwissMapLayers.Text, this.props.textLayer);
    }

    backgroundLayer.on("postrender", (event) =>
      this.renderCaption(
        event.context as CanvasRenderingContext2D | null | undefined
      )
    );

    return map;
  }

  componentWillUnmount() {
    this.unregisterEventListeners();
  }

  private renderCaption(context?: CanvasRenderingContext2D | null) {
    if (!context) return;
    if (!this.props.producedAt) return;

    const caption = `Source: BAFU – ${format(
      this.props.producedAt,
      "dd.MM.yyyy HH:mm"
    )}`;
    const font = '"Frutiger Neue",Helvetica,Arial,sans-serif';
    let fontSize = 9;
    if (window.devicePixelRatio) {
      fontSize = fontSize * window.devicePixelRatio;
    }
    const marginLeft = 5;
    const marginBottom = 10;

    context.save();
    context.font = `${fontSize}px ${font}`;
    context.globalAlpha = 0.5;
    context.fillText(
      caption,
      marginLeft,
      context.canvas.height - fontSize - marginBottom
    );
    context.restore();
  }

  private registerEventListeners() {
    this.map.on("click", this.handleClick.bind(this));
    this.map.on("pointermove", this.handlePointerMove.bind(this));
    window.addEventListener("resize", this.handleResize.bind(this));

    let currZoom = this.map.getView().getZoom();
    this.map.on("moveend", () => {
      const newZoom = this.map.getView().getZoom();
      if (currZoom !== newZoom) {
        currZoom = newZoom;
        if (this.props.onZoomLevelChange) this.props.onZoomLevelChange();
      }
    });
  }

  private unregisterEventListeners() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleClick(ev: MapBrowserEvent<any>) {
    if (this.props.onClick) {
      const features: Array<Feature> = [];
      this.forEachFeatureAtPixelWithClickable(ev.pixel, (feat, clickable) => {
        if (clickable) features.push(feat);
      });
      this.props.onClick(features);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handlePointerMove(ev: MapBrowserEvent<any>) {
    // Only emit `onHover` event when no buttons are pressed, to avoid
    // unexpected behavior on touch devices, when there is a slight
    // drag (see BAFU-1780)
    if (this.props.onHover && ev.originalEvent.buttons === 0) {
      const features: Array<Feature> = [];
      const clickables: Array<boolean> = [];
      this.forEachFeatureAtPixelWithClickable(ev.pixel, (feat, clickable) => {
        features.push(feat);
        clickables.push(clickable);
      });

      const anyClickable = clickables.some(Boolean);
      const cursor = anyClickable ? "pointer" : "default";
      this.map.getTargetElement().style.cursor = cursor;
      this.props.onHover(features);
    }
  }

  private forEachFeatureAtPixelWithClickable(
    pixel: Pixel,
    cb: (feature: Feature, clickable: boolean) => void
  ): void {
    this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
      const { clickFilter } = this.props;
      const clickable = clickFilter
        ? clickFilter(feature as Feature, layer)
        : true;
      cb(feature as Feature, clickable);
    });
  }

  /**
   * When resizing the window, make sure the whole country is visible
   * (a.k.a. resize map)
   */
  private handleResize(): void {
    const view = this.map.getView();
    const zoom = view.getZoom();

    // Disable current zoom limits to make the `fit` below work
    view.setMinZoom(-1000);
    view.setMaxZoom(1000);

    // "Zoom" to make the whole country visible
    this.map.getView().fit(MAP_EXTENT);

    // Set new zoom limits relative to current zoom level (determined
    // with the above `fit`)
    this.setZoomLimits();

    if (!zoom) return;

    if (zoom < view.getMaxZoom() && zoom > view.getMinZoom()) {
      view.setZoom(zoom);
    } else if (zoom > view.getMaxZoom()) {
      view.setZoom(view.getMaxZoom());
    } else if (zoom < view.getMinZoom()) {
      view.setZoom(view.getMinZoom());
    }
  }

  /**
   * Set zoom limits relative to current zoom level (assuming this is
   * the neutral zoom level 0)
   */
  private setZoomLimits(): void {
    const view = this.map.getView();
    const currentZoom = view.getZoom() || 0;
    const zoomMin = this.props.zoomMin || ZOOM_MIN_DEFAULT;
    const zoomMax = this.props.zoomMax || ZOOM_MAX_DEFAULT;
    view.setMinZoom(currentZoom + zoomMin);
    view.setMaxZoom(currentZoom + zoomMax);

    if (this.props.onZoomLevelChange) this.props.onZoomLevelChange();
  }

  private zoomIn(): void {
    const view = this.map.getView();
    const zoom = view.getZoom() || 0;
    view.setZoom(zoom + ZOOM_STEP);

    if (this.props.onZoomLevelChange) this.props.onZoomLevelChange();
  }

  private zoomOut(): void {
    const view = this.map.getView();
    const zoom = view.getZoom() || 0;
    view.setZoom(zoom - ZOOM_STEP);

    if (this.props.onZoomLevelChange) this.props.onZoomLevelChange();
  }

  /* Display hint to drag map with two fingers, when dragged with one finger on mobile */
  private updateTouchDragOverlay(show: boolean) {
    const overlay = this.touchDragOverlayRef.current;
    if (overlay) {
      const display = show ? "flex" : "none";
      if (overlay.style.display !== display) {
        overlay.style.display = display;
      }
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <div
          ref={this.mapRef}
          style={{ width: "100%", height: "100%" }}
          onTouchMove={(e) =>
            this.updateTouchDragOverlay(e.touches.length == 1)
          }
          onTouchEnd={() => this.updateTouchDragOverlay(false)}
        />

        <SwissMapControls
          legendLink={this.props.legendLink}
          type={this.props.type}
          onZoomOut={this.zoomOut.bind(this)}
          onZoomIn={this.zoomIn.bind(this)}
        />
        <SwissMapTouchDragOverlay ref={this.touchDragOverlayRef} />
      </div>
    );
  }
}
